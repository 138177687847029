@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Telugu:wght@300&display=swap');

:root {
  --primary: #21574A;
  --secondary: #7ab340;
}

html {
    scroll-snap-type: y mandatory;
    scrollbar-color: var(--primary) #e0e0e0;
    scrollbar-width: thin;
}
.App {
  text-align: center;
  height: 100vh;
  /* overflow-y: scroll;
  scroll-snap-type: y mandatory; */
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .pts-logo {
    animation: logo-bounce infinite 5s linear;
    -webkit-animation: logo-bounce infinite 5s linear; /* Safari 4+ */
    -moz-animation:    logo-bounce infinite 5s linear; /* Fx 5+ */
    -o-animation:      logo-bounce infinite 5s linear; /* Opera 12+ */
  }
}

.App-header {
  background-color: #ffffff;
  width:100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  color: white;
  /* scroll-snap-align: start; */
  /* scroll-snap-destination: 100px 0%; */
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.App-main {
  font-family: 'Noto Serif Telugu', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  scroll-snap-type: 'y mandatory'
}
.App-footer {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  /* scroll-snap-align: center; */
  font-family: 'Open Sans', sans-serif;
}

.App-link {
  color: #61dafb;
}
.MuiTypography-body1,
.MuiTypography-h5 {
    font-family: 'Open Sans', sans-serif !important
}
.MuiTypography-colorTextSecondary {
    color: #666666 !important;
    font-weight: 700 !important;
}
.MuiIconButton-root {
    flex: 1 1 30% !important;
}
.MuiTooltip-tooltip {
    background-color: black !important;
    font-size: 0.8rem !important;
}
.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 1;
}

.w-100 {
    width: 100%;
}
.service { font-weight:700 }
.bg {
    position: relative;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bg1 { background-image: url('./images/bg1.jpg'); overflow: hidden; }
.bg2 { background-image: url('./images/bg5.jpg'); overflow: hidden; }
.bg3 { background-image: url('./images/bg3.jpg'); overflow: hidden; }
.bg-cover {
    position:absolute;
    overflow: hidden;
    top: 50%;
    width: 5%;
    height: 5%;
    background-color: var(--primary);
    opacity: 0;
    /* border-radius: 25px; */
    /* transform: translate(0px) rotateZ(-20deg); */
    transform-origin: 50% 50%;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function:linear;
    transition-delay: 2s;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}
.animate {
    width: 120%;
    height: 120%;
    top: 0%;
    opacity: 0.7;
}
.row {
    display: flex;
    align-items:center;
    justify-content:flex-end;
    width:100%;
}
.logo-container {
    display: flex;
    align-items:center;
    justify-content:flex-start;
    width:100%;
}
.heading {
    font-size:2.7rem;
    padding:0px 20px;
    color:#000000;
    font-family: 'Open Sans', sans-serif;
}
.subheading {
    font-size:2rem;
    color:#ffffff;
    font-family: 'Open Sans', sans-serif;
    margin:0;
    padding: 0px 20px;
}
.expand {
    transform: rotate(0deg);
    margin-left: auto;
}
.expanded {
    transform: rotate(180deg);
}
.p-m {
    margin-bottom: 60px;
}
.pts-sketch {
    width: 250px;
    padding:10px;
}
.pts-sketch-anim {
    flex: 0 1 33%;
}
.pts-milos {
    width: 250px;
    padding: 10px;
}
.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
}
.fs {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    white-space: pre-wrap;
}
.faicon {
  display:block;
  color:#ffffff;
  overflow:hidden;
  text-decoration:none;
  font-size:2.5rem;
}
.fablack {
    color:#ffffff;
    font-size:5rem;
}

.socialmedia {
    display: flex;
    flex-wrap:nowrap;
    align-items: center;
    justify-content: center;
}
.sm-item {
    flex: 0 0 33%;
}
.sm-header {
    margin-bottom: 60px;
    justify-content: center;
    width:100%;
}
.projects-container {
    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width:100%;
    min-height: 100vh;
    background-color: #ffffff;
    background-image: url("./images/bg5.jpg");
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.proj-item { 
    /* margin: 20px;  */
    display:flex; 
    justify-content: center; 
}
.link {
    text-decoration:none;
    color: #ffffff;
    font-weight: 700;
    /* text-shadow: 1px 1px 1px #000; */
}
.pts-sketch-sa {
    height: 18vw;
    width: 18vw;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
    /* transition: all 1s ease-in, */
}
.pts-sketch-sa:hover {
    width: 20vw;
    height: 20vw;
}
.pts-logo {
    height: 12vmin;
    width: 13vmin;
    pointer-events: none;
}
.pts-logo-plain {
    height: 25vmin;
    width: 25vmin;
    /* position: absolute;
    top: 30%;
    left: 50%; */
    transform: translate(-50%, -50%);
    animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}
.pts-logo-center {
    width: 30vmin;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -100;
    transition: opacity 1.5s ease-in;
}
.pts-container {
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.dino-container {
    background-image: url('./images/pts-bg.jpeg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size:contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    width: 25vmin;
    height: 25vmin;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.name-address {
    font-size: 1rem;
    text-align:left;
    line-height:2rem;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items:center;
}
.text {
    padding:40px;
    font-size:2.2rem;
}
.text-a {
    color: #ffffff;
    padding: 10px;
    font-size: 1.4em;
    z-index: 100;
    text-align: left;
}
.text-m {
    padding:30px;
    font-size:1.8rem;
}
.text-tab {
    font-size:1.5rem;
    margin: 0 auto;
}
.text-tab-1 {
    font-size: 1.3rem;
    position: absolute;
    top: 60%;
    left: 5%;
    width: 25vw;
    opacity: 1;
    /* color: var(--secondary); */
    color: #000000;
	animation-name: lettersOn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}
.text-tab-2 {
    font-size:1.2rem;
    position: absolute;
    top: 50%;
    right: 5%;
    width: 20vw;
    opacity: 1;
    color: #000000;
	animation-name: lettersOn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}
.lineBottom {
    margin: 35px 0;
    height: 2px;
    border: 0;
    background: linear-gradient(
        to right,
        #272222 0%,
        #7ab340 40%,
        #7ab340 60%,
        #272222 100%
    );
    width: 0%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    animation: lineShow 0.5s linear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}
.stone-apt {
    height: 30vmin;
}


.heading span span {
    position: relative;
    opacity: 0;
    animation: move-text;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}
#s1 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

#svg1 {
  -webkit-clip-path: polygon(0% 0%, 100% 50%, 40% 100%);
  clip-path: polygon(0% 0%, 100% 50%, 40% 100%);
  margin: 0 auto;
  width: 95vw;
}
#s1 h1 {
  color: #ffffff;
  position: relative;
  /* -webkit-transform: rotate(-23deg);
  -ms-transform: rotate(-23deg);
  transform: rotate(-23deg); */
  font-size: 4vw;
  top: -33vw;
  left: -25vw;
  text-shadow: -5px 3px #000000;
  font-family: 'Open Sans', sans-serif;
  margin: 25px;
  height: 0;
}
#s1d {
  height: 30vw;
  width: 100%;
  margin: 0;
  margin-top: -2vw;
}
.left { float: left; }
.right { float: right; }
.p1-spanl,
.p1-spanr {
  height: 100%;
}
.p1-spanl {
  width: 40%;
  shape-outside: polygon(0px 0px, 100% 0px, 32% 95%);
  color: #ffffff;
}
.p1-spanl:before {
  content: "";
  height: 0.3vw;
  width: 25%;
  position: absolute;
  background-color: #fccf95;
  -webkit-transform: rotate(-48deg);
  -ms-transform: rotate(-48deg);
  transform: rotate(-48deg);
  left: 17vw;
  top: 64vw;
}
.p1-spanr {
  width: 60%;
  shape-outside: polygon(7px -1px, 76.69% 6px, 98.63% 67.74%);
}
.p1-spanr:before {
  content: "";
  height: 0.3vw;
  width: 50%;
  position: absolute;
  background-color: #fccf95;
  right: 10px;
  top: 55vw;
}
p.goal {
  padding: 0;
  margin: 0;
  font-size:1.4rem;
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.tooltip {
    background-color: transparent !important;
}
/* .scroll-top {
    background-color: #ffffff !important;
} */

.thought {
  display:flex;
  background-color:#fff;
  padding:20px;
  border-radius:30px;
  min-width:40px;
  max-width:280px;
  min-height:40px;
  margin:20px;
  position:relative;
  align-items:center;
  justify-content:center;
  text-align:center;
  color: #000000;
  font-size: 0.8em;
  align-self: flex-start;
}
.thought:before,
.thought:after {
  content:"";
  background-color:#fff;
  border-radius:50%;
  display:block;
  position:absolute;
  z-index:-1;
}
.thought:before {
  width:44px;
  height:44px;
  top:-12px;
  left:28px;
  box-shadow:-50px 30px 0 -12px #fff;
}
.thought:after {
  bottom:-10px;
  right:26px;
  width:30px;
  height:30px;
  box-shadow:40px -34px 0 0 #fff,
             -28px -6px 0 -2px #fff,
             -24px 17px 0 -6px #fff,
             -5px 25px 0 -10px #fff;

}
.g-line {
    width: 20vw;
    position: absolute;
    top: 80%;
    left: 0;
}
.g-line line {
    stroke: var(--secondary);
    stroke-width: 0.3px;
    fill: transparent;
}

.arrow {
  width: 30px;
  height: 40px;
  /* position: absolute;
  top: 50%;
  left: 50%; */
  z-index: 999;
}

.arrow path {
  stroke: var(--primary);
  stroke-width: 2px;
  fill: transparent;
  animation: down 2s infinite;
}

@keyframes down {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}

.arrow path.a1 { animation-delay: 0s; }
.arrow path.a2 { animation-delay: 0.5s; }
.arrow path.a3 { animation-delay: 1s; }


@keyframes fadeInDown {
    0% { opacity: 0;
         -webkit-transform: translate3d(0,-20%,0) !important;
         transform: translate3d(0,-20%,0) !important;
       }
   100% { opacity: 1;
        -webkit-transform: translate3d(0,0,0) !important;
        transform: translate3d(0,0,0) !important;
      }
}
@keyframes fadeInOpacity {
	0% { opacity: 0; }
    50% { opacity: 0.3; }
	100% { opacity: 1; }
}
@keyframes move-text {
    0% { bottom: -0.2em; opacity: 1; }
    50% { bottom: 0.2em; }
    100% { bottom: 0; opacity: 1; }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes logo-bounce {
        0%   { transform: scale(1,1)    translateY(0); }
        10%  { transform: scale(1.1,.9) translateY(0); }
        30%  { transform: scale(.9,1.1) translateY(-18px); }
        50%  { transform: scale(1,1)    translateY(0); }
        100% { transform: scale(1,1)    translateY(0); }
}
@-webkit-keyframes logo-bounce {
        0%   { transform: scale(1,1)    translateY(0); }
        10%  { transform: scale(1.1,.9) translateY(0); }
        30%  { transform: scale(.9,1.1) translateY(-18px); }
        50%  { transform: scale(1,1)    translateY(0); }
        100% { transform: scale(1,1)    translateY(0); }
}
@-moz-keyframes logo-bounce {
        0%   { transform: scale(1,1)    translateY(0); }
        10%  { transform: scale(1.1,.9) translateY(0); }
        30%  { transform: scale(.9,1.1) translateY(-18px); }
        50%  { transform: scale(1,1)    translateY(0); }
        100% { transform: scale(1,1)    translateY(0); }
}
@-o-keyframes logo-bounce {
        0%   { transform: scale(1,1)    translateY(0); }
        10%  { transform: scale(1.1,.9) translateY(0); }
        30%  { transform: scale(.9,1.1) translateY(-18px); }
        50%  { transform: scale(1,1)    translateY(0); }
        100% { transform: scale(1,1)    translateY(0); }
}
@keyframes lettersOn {
    0% {
        opacity: 0;
        color: #21574A;
        text-shadow: 0px 0px 1px rgba(122, 179, 64, 1);
    }

    60% {
        color: #21574A;
        text-shadow: 0px 0px 10px rgba(122, 179, 64, 1);
    }

    100% {
        opacity: 1;
        color: #21574A;
        text-shadow: 0px 0px 3px rgba(122, 179, 64, 1);
    }
}
@keyframes lineShow {
    0%   { opacity: 0; width: 0%; }
    100% { opacity: 1; width: 100%; }
}


@media (max-width:600px) {
    .App-header,
    .App-footer,
    .App-main {
        justify-content:center;
    }
    .name-address {
        font-size: 0.9rem;
        padding: 20px 15px;
        text-align:center;
    }
    .row {
        align-items:center;
        flex-wrap:wrap;
        justify-content: center;
    }
    .heading { font-size:2.5rem; display: flex; flex-wrap: wrap; justify-content: center}
    .heading-letters { color:#000000 !important }
    .subheading { font-size:1.5rem; }
    .ats-sketch {
        width: 130px;
        padding:5px 20px;
    }
    .socialmedia { margin: 20px 0px; }
    .socialmedia > div { flex: 0 0 33%; }
    .name-address { flex: 0 90%; }
    .sm-item { margin: 10px 0px; }
    .text { padding: 5px 10px; font-size:0.9rem;}
    .fablack { font-size:3rem; }
    .sm-header { justify-content: center; }
    p.goal { padding: 10px; font-size: 0.9rem }
    .p1-spanl:before {
        width:75%;
        top: 90vw;
        left:-17vw;
        -webkit-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
        transform: rotate(-60deg);
    }
    /* .p1-spanr:before { display: none } */
    #s1d { height: 60vw }
    .dino-container { width: 25vmin; height:25vmin}
    .projects-container {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        padding: 10vh 0px;
    }
    .proj-item { margin: 20px 5px }
    .pts-sketch { width:190px }
    .pts-sketch-anim { flex: 1 1 100%;  }
    .text-tab-1,
    .text-tab-2 {
        font-size:1rem;
        top: 70%;
        left: 50%;
        width: 85vw;
        transform: translate(-50%,-50%);
    }
    .pts-logo-center {
        width: 40vw;
        top: 20%;
    }
    .pts-sketch-sa {
        top: 27%;
        left: 60%;
        width: 40vw;
        height: 40vw;
    }
    .MuiTabs-flexContainer {
        display:flex;
        justify-content: space-between;
    }
    .arrow {
      width: 20px;
      height: 30px;
      transform:translateY(-5vh);
    }
    .text-a {
        margin: 5vh 5vw;
        font-size: 1.3em;
        z-index: 100;
        text-align: left;
    }
    .bg {
        background-attachment: scroll;
    }
    .bg3 {
        background-position: 70% 50%;
        overflow: hidden;
    }

}
@media (min-width:600px) {
    .projects-container {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;
        /* padding: 40px 0px; */
    }
    .proj-item { 
        /* margin: 20px;  */
        display:flex; 
        justify-content: center; 
    }
    .text-tab-1 {
        font-size:0.9rem;
        top: 70%;
        left: 10%;
        width: 30vw;
    }
    .text-tab-2 {
        font-size:0.9rem;
        top: 70%;
        left: 80%;
        right: 0;
        width: 30vw;
        transform: translate(-50%,-50%);
    }
}
@media (min-width:1200px) {
    .projects-container {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;
    }
    .proj-item { 
        /* margin: 20px;  */
        display:flex; 
        justify-content: center; 
    }
    .text-tab-1 {
        font-size:1.2rem;
        top: 70%;
        left: 10%;
        width: 20vw;
    }
    .text-tab-2 {
        font-size:1.2rem;
        top: 70%;
        left: 80%;
        right: 0;
        width: 25vw;
        transform: translate(-50%,-50%);
    }
}
